@import url('https://fonts.googleapis.com/css2?family=Kalnia:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Kalnia:wght@600&display=swap');

.home2 {
    padding-top: 60px;
    padding-bottom: 60px;

}

.themecolor {
    color: #a13704;
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    margin-bottom: 15px;
}

.themecolor2 {
    color: #2c2b23;
    font-size: clamp(30px, 4vw, 43px);
    /* font-size: 43px; */
    line-height: 47px;
    font-weight: 800;
    font-family: 'DM Serif Display';
    letter-spacing: 0px;
}

.themecolor21 {
    color: #2c2b23;
    /* font-size: clamp(30px, 4vw, 43px); */
    /* font-size: 43px; */
    line-height: 47px;
    font-weight: 800;
    font-family: 'DM Serif Display';
    letter-spacing: 0px;
}

.themecolor3 {
    color: #2c2b23;
    /* font-size: clamp(35px, 4vw, 35px); */
    /* font-size: 43px; */
    line-height: 47px;
    font-weight: 800;
    font-family: 'DM Serif Display';
    letter-spacing: 1px;
}

@media (min-width: 960px) {

    .aboutParagraph2 {
        text-align: left;
        color: #2c2b23;
        font-size: 14px;
        line-height: 25px;
        letter-spacing: 0px;
        font-family: "Poppins", Helvetica, Arial, sans-serif;
        display: none;
    }

    .aboutParagraph {
        text-align: left;
        color: #2c2b23;
        font-size: 14px;
        line-height: 25px;
        letter-spacing: 0px;
        font-family: "Poppins", Helvetica, Arial, sans-serif;
        display: block;
    }

    .aboutmedia {
        display: block;
    }

    .aboutmedia2 {
        display: none;
    }
}

@media (max-width: 960px) {
    .aboutParagraph2 {
        text-align: left;
        color: #2c2b23;
        font-size: 14px;
        line-height: 25px;
        letter-spacing: 0px;
        font-family: "Poppins", Helvetica, Arial, sans-serif;
        display: block;
    }

    .aboutParagraph {
        text-align: left;
        color: #2c2b23;
        font-size: 14px;
        line-height: 25px;
        letter-spacing: 0px;
        font-family: "Poppins", Helvetica, Arial, sans-serif;
        display: none;

    }

    .aboutmedia {
        display: none;
    }

    .aboutmedia2 {
        display: block;
    }
}

.abtbtn {
    background-color: #2c2b23 !important;
    color: white;
    padding: 10px 20px 10px 20px !important;
    border-radius: 0px !important;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
}

.abtbtn2 {
    background-color: #7c4014 !important;
    color: white;
    padding: 10px 20px 10px 20px !important;
    border-radius: 0px !important;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
}

.abtbtn2:hover {
    background-color: #7c4014 !important;
}

.abtbtn:hover {
    background-color: #2c2b23 !important;
}

.counter1 {
    font-family: inherit;
    font-size: 60px;
    color: #444;
    line-height: 60px;
}

.cardimg1 {
    transition: transform 0.3s ease;
}

.cardimg1:hover {
    transform: scale(1.1);
    /* Adjust the scale factor for your desired zoom level */
}

@media (min-width: 960px) {
    .extraGrid {
        display: none !important;
    }

    .carlarge {
        display: block;
    }

    .carsmall {
        display: none;
    }
}

@media (max-width: 960px) {
    .extraGrid {
        display: block !important;
    }

    .carlarge {
        display: none;
    }

    .carsmall {
        display: block;
    }
}