.GridContainer {
    padding-top: 120px;
    padding-bottom: 80px;
    padding-left: 60px !important;
}

.texthead {
    // margin-top: 25px !important;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    font-weight: 400;
}

.form {
    padding-top: 120px;
    padding-bottom: 80px;
    background-color: #dbc9c3
}

.formhead {
    font-weight: 600;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
}

.formques {
    color: white;
    font-size: 50px;
    font-weight: 800;
    font-family: 'DM Serif Display';
    margin-top: -10px;
}

.abtbtn {
    background-color: #7c4014 !important;
    color: white;
    padding: 10px 20px 10px 20px !important;
    border-radius: 0px !important;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
}

.abtbtn:hover {
    background-color: #7c4014 !important;
}