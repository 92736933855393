.con {
    padding: 20px !important;
    background-color: #eeeeee  !important;
}
.con2 {
    padding-top: 20px !important;
    background-color: #eeeeee  !important;
}
.contact {
    background-color: #eeeeee  !important;
    padding: 80px !important;
    font-family: "Poppins", Helvetica, Arial, sans-serif !important;
}
.contact2 {
    padding: 80px !important;
    font-family: "Poppins", Helvetica, Arial, sans-serif !important;
}

.headerContact {
    color: #7c4014 !important;
    font-weight: bold !important;
}

.body {
    font-size: 14px !important;
    text-align: center;
    // padding: 20px !important;
    line-height: 30px !important;
}

.iconbtn {
    border-radius: 50px !important;
    background-color: #7c4014 !important;
    color: white !important;
}