.themecolormain {
    color: #a13704;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0px;
    font-weight: 600;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
}

.themecolor2main {
    color: #2c2b23;
    font-size: 43px;
    line-height: 50px;
    letter-spacing: 0px;
    font-weight: 800;
    font-family: 'DM Serif Display';
    text-align: left;
}

.aboutParagraphmain {
    text-align: left;
    color: #2c2b23;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0px;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    text-align: justify;
}

.aboutParagraphmain2 {
    margin-top: 10%;
    text-align: left;
    color: #2c2b23;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0px;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    text-align: justify;
}




@media (min-width: 960px) {
    .imagecontainer {
        height: 80vh;
        /* 100% of the viewport height */
        display: flex;
        align-items: space-between;
        background: linear-gradient(to bottom, #fff 70%, #e6852b 30%);
        /* Replace these colors with your desired colors */
        color: #fff;
        /* Text color on the background */
    }

    .header {
        background-image: url('../../images/allIMage.jpg');
        background-repeat: no-repeat;
        background-position: center;
        height: 50vh;
        background-size: cover;
    }

    .textstyle {
        color: white;
        font-size: 50px;
        line-height: 55px;
        letter-spacing: 0px;
        font-weight: 800;
        font-family: 'DM Serif Display';
        margin-top: 40%;
    }

    .item2 {
        padding: 50px 5% 35px;
        background-color: rgb(230, 133, 43, 0.75);
        height: 50vh;
        /* width: 50%; */

    }

    .item2Containt {
        color: #fff;
        font-family: "Poppins";
        border-bottom: 2px solid #7c4014;
        padding-bottom: 15px;
        display: inline-block;
        font-size: 14px;
        line-height: 30px;
        font-weight: 600;
        margin-top: 30%;
    }

    .item2Containt2 {
        color: #fff;
        font-family: "DM Serif Display";
        font-size: 40px;
        line-height: 50px;
        font-weight: 600;
        letter-spacing: 0px;
        margin-top: -3%;

    }

    .thridContainer {
        height: 80vh;
        background-color: #e6852b;
        display: block;
    }

    .thridContainer2 {
        height: 0vh;
        background-color: #e6852b;
        visibility: hidden;
        display: none;
    }
}

@media (max-width: 960px) {
    .imagecontainer {
        height: 50vh;
        /* 100% of the viewport height */
        display: flex;
        align-items: space-between;
        background: linear-gradient(to bottom, #fff 70%, #e6852b 30%);
        /* Replace these colors with your desired colors */
        color: #fff;
        /* Text color on the background */
    }
    .header {
        background-image: url('../../images/allIMage.jpg');
        background-repeat: no-repeat;
        background-position: center;
        // height: 45vh;
        background-size: cover;
    }

    .item1 {
        justify-content: center;
        text-align: center;
    }

    .textstyle {
        // margin-left: 6%;
        color: white;
        font-size: 30px;
        line-height: 30px;
        letter-spacing: 1px;
        font-weight: 800;
        font-family: 'DM Serif Display';
        margin-top: 20%;
    }

    .item2 {
        text-align: center;
        align-self: baseline;
        // padding: 50px 5% 35px;
        background-color: rgb(230, 133, 43, 0.75);
        height: auto;

    }

    .item2Containt {
        color: #fff;
        font-family: "Poppins";
        border-bottom: 2px solid #7c4014;
        padding-bottom: 8px;
        display: inline-block;
        font-size: 14px;
        line-height: 30px;
        font-weight: 600;
        margin-top: 30%;
    }

    .item2Containt2 {
        color: #fff;
        font-family: "DM Serif Display";
        font-size: 30px;
        line-height: 50px;
        font-weight: 600;
        letter-spacing: 0px;
        margin-top: -3%;

    }

    .thridContainer {
        height: 0vh;
        background-color: #e6852b;
        visibility: hidden;
        display: none;
    }

    .thridContainer2 {
        background-color: #e6852b;
        display: block;
    }
}


.gallery-container {
    padding-bottom: 4%;
    padding-top: 4%;
    background-color: #fff;
}

.menubg {
    position: relative;
    overflow: hidden;
}

.menubg::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url('../../images/mix.jpeg');
    background-position: center;
    background-size: cover;
    filter: blur(5px);
    /* Adjust the blur value as needed */
    opacity: 0.5;
    z-index: -1;
    pointer-events: none;
    /* Ensures that the pseudo-element is not interactive */
}