.grid-item1 {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;

}

.grid-item1.show {
    opacity: 1;
    transform: translateY(0);
}


@media (min-width: 959px) {
    .grid-container {
        /* gap: 10px; */
        /* Adjust the gap between grid items */
        height: 100vh;
        /* Adjust the height of the grid container */
        background-image: url('../../../images/frontImage.jpg');
        /* Replace 'your-background-image.jpg' with your actual image file */
        background-size: cover;
        background-repeat: no-repeat;
        overflow: hidden;
        justify-content: flex-end;
    }

    .grid-item1-box {
        z-index: 8;
        background-color: rgb(230, 133, 43, 0.75);
        visibility: visible;
        overflow: hidden;
        color: rgb(255, 255, 255);
        width: 100%;
        height: 100vh;
        opacity: 1;
        /* transform: translateY(20px);
        transition: opacity 0.5s ease-out, transform 0.5s ease-out;
        transition-delay: 2s; */

    }

    .wellcome {
        margin-left: 10%;
        margin-top: 20%;
        z-index: 9;
        text-align: left;
        font-family: "DM Serif Display";
        line-height: 70px;
        font-weight: 600;
        font-size: 55px;
        /* opacity: 1; */
        transform-origin: 50% 50% 0px;
    }

    .wellcome-containt {
        margin-left: 10%;
        margin-top: 2%;
        z-index: 9;
        text-align: left;
        font-family: "Poppins";
        line-height: 30px;
        font-weight: 200;
        font-size: 16px;
        opacity: 1;
        transform-origin: 50% 50% 0px;
    }

    .weare {
        z-index: 11;
        margin-left: 10%;
        margin-top: 8%;
        font-family: "Poppins";
        visibility: visible;
        line-height: 14px;
        letter-spacing: 4px;
        font-weight: 500;
        font-size: 13px;
        transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        transform-origin: 50% 50% 0px;
    }

    .weare2 {
        z-index: 11;
        margin-left: 10%;
        margin-top: 8%;
        font-family: "DM Serif Display";
        visibility: visible;
        line-height: 34px;
        letter-spacing: 0px;
        font-weight: 400;
        font-size: 36px;
        transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        transform-origin: 50% 50% 0px;
    }

    .mobcontent{
        display: none !important;
    }
}

@media (min-width: 600px) and (max-width: 959px) {
    .grid-container {
        /* gap: 10px; */
        /* Adjust the gap between grid items */
        height: 80vh;
        /* Adjust the height of the grid container */
        background-image: url('../../../images/frontImage.jpg');
        /* Replace 'your-background-image.jpg' with your actual image file */
        background-size: cover;
        background-repeat: no-repeat;
        overflow: hidden;
        justify-content: center;
        /* Center horizontally */
        align-items: center;
    }

    .grid-item1-box {
        margin-top: 30%;
        z-index: 8;
        background-color: rgb(230, 133, 43, 0.75);
        visibility: visible;
        overflow: hidden;
        color: rgb(255, 255, 255);
        width: 100vh;
        height: 50vh;
        opacity: 1;
        justify-content: center;
        /* Center horizontally */
        align-items: center;
        /* Center vertically */
        /* transform: translateY(20px);
        transition: opacity 0.5s ease-out, transform 0.5s ease-out;
        transition-delay: 2s; */

    }

    .br1 {
        display: block;
    }

    .wellcome {
        margin-top: 5%;
        z-index: 9;
        text-align: center;
        font-family: "DM Serif Display";
        line-height: 40px;
        font-weight: 400;
        font-size: 40px;
        /* opacity: 1; */
        transform-origin: 50% 50% 0px;
    }

    .wellcome-containt {
        display: none;
    }

    .weare {
        z-index: 11;
        margin-left: 8%;
        margin-top: 5%;
        text-align: left;
        font-family: "Poppins";
        visibility: visible;
        text-align: left;
        line-height: 11px;
        letter-spacing: 1px;
        font-weight: 500;
        font-size: 10px;
        transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        transform-origin: 50% 50% 0px;
    }

    .weare2 {
        z-index: 11;
        margin-left: 8%;
        margin-top: 3%;
        font-family: "DM Serif Display";
        visibility: visible;
        line-height: 34px;
        letter-spacing: 0px;
        font-weight: 400;
        font-size: 30px;
        transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        transform-origin: 50% 50% 0px;
    }
    
    .mobcontent{
        display: none !important;
    }
}

@media (max-width: 599px) {
    .grid-container {
        height: 50vh;
        background-image: url('../../../images/frontImage.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        align-items: self-end;
    }

    .grid-item1-box {
        color: rgb(255, 255, 255);
    }

    .br1 {
        display: block;
    }

    .wellcome {
        margin: 5%;
        z-index: 9;
        text-align: left;
        font-family: "DM Serif Display";
        line-height: 30px;
        font-weight: 400;
        font-size: 30px;
        /* opacity: 1; */
        transform-origin: 50% 50% 0px;
    }

    .wellcome-containt {
        display: none;
    }

    .weare {
        z-index: 11;
        margin: 5%;
        margin-top: 5%;
        font-family: "Poppins";
        visibility: visible;
        text-align: left;
        line-height: 11px;
        letter-spacing: 0px;
        font-weight: 500;
        font-size: 10px;
        transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        transform-origin: 50% 50% 0px;
    }

    .weare2 {
        /* z-index: 11;
        margin-top: 5%;
        font-family: "DM Serif Display";
        visibility: visible;
        line-height: 20px;
        letter-spacing: 0px;
        font-weight: 400;
        font-size: 20px;
        transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        transform-origin: 50% 50% 0px; */
        display: none;
    }

    .mobcontent {
        display: block !important;
        justify-content: right !important;
    }

    .weare3 {
        font-family: "DM Serif Display";
        font-weight: 400;
        font-size: 20px;
        color: white !important;
        text-align: right !important;
        margin-right: 2% !important;
    }
}

/* 
.grid-item1-box.rotate {
    opacity: 1;
    transform: translateY(0);
} */