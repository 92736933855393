.themecolormain {
    color: #a13704;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0px;
    font-weight: 600;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
}

// .header{
//     position: relative;
//     overflow: hidden;
//     transition: transform 0.3s ease-in-out;
// }
.themecolor2main {
    color: #2c2b23;
    font-size: 43px;
    line-height: 50px;
    letter-spacing: 0px;
    font-weight: 800;
    font-family: 'DM Serif Display';
    text-align: left;
}

.aboutParagraphmain {
    text-align: left;
    color: #2c2b23;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0px;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    text-align: justify;
}

.heading1 {
    text-align: left;
    font-size: 14px !important;
    line-height: 27px;
    letter-spacing: 0px;
    color: #fff;
    font-weight: 400;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    margin-top: 10% !important;
    text-transform: uppercase;

}

.banner-background {

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 550.44px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.car_them {
    display: flex;

    .car_item {
        display: flex;
        justify-content: center;

    }
}



.testHeading {
    color: #a13704;
    line-height: 20px;
    letter-spacing: 0px;
    font-weight: 600;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
}

.testHeading2 {
    color: #2c2b23;
    font-size: 50px;
    font-weight: 800;
    font-family: "DM Serif Display", Helvetica, Arial, sans-serif;
}
@media (max-width: 960px ) { 
    .carcontainer {
        height: auto;
        background-color: #fff;
    }
   }
 @media (min-width: 960px ) { 
    .carcontainer {
        height: 90vh;
        background-color: #fff;
    }
   }