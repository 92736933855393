.gallery-info {
    text-align: center;
    padding: 16px;
}

.gallery-title {
    font-size: 24px;
    margin-bottom: 8px;
}

.gallery-description {
    font-size: 16px;
    margin-bottom: 16px;
}

.redirect-button {
    display: block;
    margin: 0 auto;
    padding: 8px 16px;
    background-color: #7c4014;
    color: #fff;
    text-decoration: none;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.middle-item {
    background-color: #000;
    color: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.image-container {
    padding: 16px;
    text-align: center;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    transform: perspective(500px) rotateX(0.5deg);
}

.image {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease-out; /* Add a smooth transition effect */
  }
  
  .image-container:hover .image {
    transform: scale(1.1); /* Zoom in on hover */
  }
  