@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.drawer_text {
    color: #ffffff !important;
    font-size: 16px !important;
    justify-content: center !important;
    font-family: 'Montserrat' !important;
    font-weight: 600 !important;
    letter-spacing: 1 !important;
}

.vertical-center {
    top: 20%;
    transform: translateY(-50%);
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
    background-color: #2c2b23 !important;
    color: #e6852b !important;
}

@media screen and (max-width: 600px) {
    .top {
        display: block !important;
    }

    .side {
        display: none !important;
    }
}
@media (max-width: 960px ) { /* Adjusted for sm screen size or below */
   .secondGrid{
    display: none !important;
   }
   .thirdGrid{
    display: block !important;
   }
  }
@media (min-width: 960px ) { 
   .thirdGrid{
    display: none !important;
   }
  }
// .MuiDrawer-paper {
//     width: 20% !important;
//     background-color: #2c2b23 !important;
//     box-sizing: border-box !important;
// }
